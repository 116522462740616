import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main",
  id: "main"
};
import { onMounted } from 'vue';
// onMounted(()=>{
//   console.log('document.getElementById("main")',document.getElementById("main"));
// var browerWidth = window.innerWidth; //浏览器可视宽度
// var baseWidth = 1080; //设计稿宽度
// var zoomValue = browerWidth / baseWidth; //缩放比例计算
// document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
// window.onresize = function () { //窗口尺寸变化时，重新计算和缩放
//   browerWidth = window.innerWidth;
//   zoomValue = browerWidth / baseWidth;
//   document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
// }
// })

export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};