import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4d4977a2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default {
  __name: 'Home',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Header), _createVNode(_component_router_view), _createVNode(Footer)]);
    };
  }
};