import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createI18n} from 'vue-i18n';
import './utils/rem.js';
import vant from 'vant';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css' 
import 'video.js/dist/video'



import "vant/lib/index.css";
const i18n = createI18n({
    locale: 'zh-CN', //切换语言
    legacy:false,
    messages: {
      'zh-CN': require('../src/lang/zh.js'),
      'en-US': require('../src/lang/en.js'),
      'zh-HK': require('../src/lang/tc.js'),
    },
  });
 
createApp(App).use(i18n).use(router).use(vant).use(ElementPlus).mount('#app')
