export const lang ={
    a1:'Download',
    a2:"LOCK CHAT makes connections more valuable",
    a3:"A free, simple, safe and reliable community communication app",
    a4:"Learn more",
    a5:"Download",
    a6:"What is LockChat?",
    aa6:"LockChat relies on blockchain technology and AIGC technology, has",
    a7:"Instant chat, encrypted currency red envelopes, AI creation and other functions, ",
    a8:"Created a freer, safer,",
    a9:"Provides secure instant messaging and storage solutions in the Web3 environment.",
    a10:"  When you use LockChat, all chat content will be end-to-end encrypted, each message sent also has its own security lock and security key, and all information will be automatically end-to-end encrypted to ensure the security of all chat information sex and privacy.",
    a11:"LockChat calls will also be end-to-end encrypted, which means that neither LockChat nor a third party can listen to the call content. The end-to-end encrypted chat, file transfer, picture, and video server will not save any chat records of the user. Each client When logging in, a SHA256 value will be generated locally and the value will be encrypted through an asymmetric encryption algorithm. The encryption result will be uploaded to the server. When the message is sent, the recipient's secret key will be obtained. The server will send the message after receiving the data. to the corresponding recipient. After receiving the data, the recipient decrypts it with its own secret key, thereby realizing encrypted data transmission and ensuring the absolute security of user messages. Since the data uses end-to-end encryption technology, the server cannot crack it, and the data cannot be cracked by the server. This means that data preservation is worthless.",
    a12:"Encrypted chat",
    a13:"Image file encryption",
    a14:"Voice and video encryption",
    a15:"Do not save chat history",
    a16:" You can quickly create a group of 10,000 people, chat and share files with multiple people in the group, and carry out convenient and efficient multi-party communication and dialogue. 100 people use asymmetric super encryption technology, which is the same as end-to-end sending asymmetric encryption The technology is the same. Every time a user sends a message in the group, it needs to be encrypted 100 times and sent to the 100 people through the server pipeline, and then everyone uses their own key to decrypt it. Due to the performance requirements of tens of thousands of people, a message is encrypted 10,000 Encrypted through the server pipeline and sent to 10,000 people at a time, the client consumes more power, and the server performance is affected. More than 100 million people cannot use asymmetric super-strong encryption. For more than 100 million people, we use Telegram's code to open source million people. Multi-directional encryption technology is used for encryption, which also ensures the data security of thousands of people.",
    a17:"Thousands of people chatting",
    a18:"Share voice",
    a19:"Share video",
    a20:"Share files",
    a21:" The community includes my circle of friends (where you can share life pictures and text information), voice sharing, online radio (real-time interaction with anchors in micro-communities and live broadcast areas), short video sharing, and online courses (where you can share your experiences online) ), online meetings, and video conferences with tens of thousands of people. You can post updates to share your life and experiences, and you can also like, share, and reward the content posted by your friends.",
    a22:"Online Courses/Conferences",
    a23:"Short video sharing",
    a24:"Internet radio",
    a25:"Article sharing",
    a26:" LockChat will open up to third parties to access various web apps based on the era of tens of thousands of people and communities, such as games, shopping, information and other rich and diverse contents. It is committed to improving user experience in all aspects and providing a platform for developers. Big stage.",
    a27:"1. Open mobile platform: access the open platform, allowing your application to support sharing, collection and payment;",
    a28:" 2. Support user login: Let your website support login using LockChat account;",
    a29:"3. Industry solutions: Become the third platform of open platforms, providing operational services and industry solutions for enterprise-level users;",
    a30:" 4. IM services: Provide IM services and cooperate with third-party companies.",
    a31:"Authorized login",
    a32:"Application value sharing",
    a33:"Social game sharing",
    a34:"Industry Solutions",
    a35:"User-centered design experience",
    a36:"Based on the user experience-centered UI design style, the LockChat interface maintains consistency and structure, making users feel relaxed and comfortable, embodying the design principles of clarity, flexibility, and efficiency.",
    a37:"LockChat open platform",
    a38:"Access to the LockChat open platform can also make your app and web site ",
    a39:"Supports using LockChat account to log in",
    a40:"LockChat currently supports 16 national languages ​​and is an end-to-end encryption",
    a41:"Chat platform, the server does not save any chat records, currently LockChat ",
    a42:"The mobile terminal supports IOS & Android, H5,",
    a43:"Computer version supports H5 and WebApp",
    a44:"(Accessed by Chrome, FireFox and Safari browsers), Mac, ",
    a45:"Windows version.",
    a46:"Value creators enjoy value",
    a47:"LockChat provides a rich variety of functions and entertainment applications for the community. We advocate that users who create value enjoy value through their applications. The applications are open to third-party developers. Developers and community organizers can operate their own communities. With the scale of community users With the increase, the value of the community is also growing. Developers, community initiators, and community members can make profits through the application of the account sharing model.",
    a48:"Learn more",
    a49:"LockChat Tribal Age",
    a50:"LockChat exists for community connection. We aim to build a valuable community and rebuild the connection and trust between users. The advancement of technology has continuously changed the way of communication between users, gradually changing from specific public groups to specific The era of the Internet for niche groups has made it easier for everyone to connect, and because it is too easy to connect without geographical restrictions, the world has returned to the `tribal era`, but tribes are different from the past, they are open, They can communicate with each other at any time. At the same time, a person does not belong to a specific tribe. He can belong to several different tribes at the same time.",
    a51:"What users say",
    a52:"Thank you very much for the user reviews of LockChat. If you have",
    a53:"Scan to download LockChat, protect your privacy, and start your encrypted chat journey...",
    a54:"Encrypted chat",
    a55:"Thousands of people chatting",
    a56:"Community era",
    a57:"Development Platform",
    a58:"Create application",
    a59:"By filling in the application name, application introduction, application icon, application download zip address and application test link address, developers can create mobile applications and H5 applications.",
    a60:"App review",
    a61:"Developers develop products based on the application API provided by LockChat. After development is completed, they submit the application for review. The LockChat team will review the application information to ensure application quality.",
    a62:"By going online",
    a63:"After the developer submits the application, the LockChat operation team will conduct a review. After the review is passed, the developer can see his application in the LockChat app store, and can organize his own users to promote it in LockChat.",
    a64:"Content creation platform: LockChat is also a content creation platform. Users can conduct: online meetings, post videos, post pictures, create articles, radio stations, ask questions, etc. and receive rewards from the platform and users. At the same time, communicators will also receive rewards from the platform and users. Get rewards for communication value.",
    a65:"Game game application: The application platform is open to third parties to play various games through user authorization. Users can earn money by playing games and rewards for sharing games.",
    a66:"Community application: Users can integrate their various application products such as news, online shopping malls, and item trading applications into the LockChat platform, manage and expand their users through the community, including managing user relationships and using the platform to reward managed users. .",
    a67:"Because the advent of the blockchain era has allowed communities to exert unimaginable power, it has also allowed us to redefine and understand the value of communities. More importantly, it lies in more efficient connections and value exchanges between tribes, forming a A new functioning economy that is efficient and profitable.",
    a68:"1. Tribal value exchange",
    a69:"The term tribal economy has returned to everyone's attention because of the blockchain. We know that popular books such as `Tribal Civilization` describe the conflict of civilizations. In fact, the essence is still a conflict between economic interests. Today we want to rectify the name of the tribal economy. It is a new trust and value relationship generated by a group of users with common interests, understanding, and values ​​who interact, communicate, collaborate, and infect together. Establishing emotional connection and trust between communities will create a new tribal economy. Because the advent of the blockchain era has allowed communities to exert unimaginable power, it has also allowed us to redefine and understand the value of communities. What is more important is more efficient connections and value exchanges between tribes, forming A new operating economy that is efficient and effective.",
    a70:"2. Tribal economy",
    a71:"Tribe is also an organizational form. To maintain the normal operation of this tribal organizational form, each individual in the tribe can generate value and obtain benefits, and will also undergo self-cycle iteration, which is the same as the biological law, just like the cells in the human body. Similarly, each cell should receive nutritional supplements, and when a group of cells die, new cells must be replaced to ensure the structural integrity of the tissue.",
    a72:"3. Tribal creature development",
    a73:"The tribe is essentially a set of biological ecosystems within a small range. The tribe itself must have the ability to grow, digest, and replicate itself, and it can only last for a long time based on decentralization and the principle of tribal consensus. The community consensus mechanism determines Clan development cycle.",
    a74:"4. Tribal Consensus Governance",
    a75:"A secret chat metaverse with more interactive value,",
    a76:"LockChat product images",
    a77:"The following is a partial screenshot of the LockChat product, showing some interfaces and functions. If",
    a78:"You want to learn more about downloading the App",
    a79:"If you have any good ideas or suggestions, you can send us an email.",
    a80:"Make the LockChat experience better!",

 }