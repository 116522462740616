import axios from "axios";

import configURL from './config.js'
let baseURL = configURL.http_url;
const service = axios.create({
   
    baseURL,
	// timeout:6000
    // baseURL: 'https://dex.xphoenix.club/api/v1',
    // baseURL:'https://bsc.SnowBall.club/api/v1'
});


service.interceptors.request.use((config) => {
    config.data = config.data
    return config
});

// ajax响应拦截器;
service.interceptors.response.use(
    (res) => {
        // 获取后端返回的数据与状态码;
        // 状态码位200时, 则无异常;
        if (res.status === 200) {
            return res.data;
        } 
    },
  
);
export default service;