export const lang ={
    a1:'下载',
    a2:"LockChat 让连接更有价值",
    a3:"一个免费、简单、安全可靠的社区通讯App",
    a4:"了解更多",
    a5:"下载",
    a6:"LockChat是什么？",
    aa6:" LockChat 依托区块链技术和AIGC技术，拥有 ",
    a7:"即时聊天、加密货币红包、AI创作等功能， ",
    a8:"为10亿+Web3用户打造出了一个更自由、更安全、 <br>更具互动价值的密聊元宇宙，",
    a9:"提供了Web3大环境下的安全即时通信和存储解决方案。",
    a10:"  当您使用LockChat时,所有聊天内容都会进行端到端的加密,每一条发送的信息也有自己的安全锁和安全密钥,而且所有的信息都会自动进行端到端的加密,可确保所有聊天信息的安全性和隐私性。",
    a11:"LockChat的通话也会进行端到端的加密，这意味着LockChat和第三方均无法听取通话内容,端到端加密聊天、传送文件、图片、视频服务器不会保存用户的任何聊天记录,每个客户端登陆的时候会使用用本机产生生一个SHA256的值并将该值通过非对称加密算法进行加密，加密结果上传给服务端，消息发送时会获取接受方的秘钥，服务器收到数据之后发给对应的接收方,接收方收到数据之后再通过自己的秘钥进行解密，从而实现数据加密传输,保证用户消息的绝对安全,由于数据采用了端到端加密技术,服务器端无法破解,也就意味着数据保存毫无价值。",
    a12:"加密聊天",
    a13:"图片文件加密",
    a14:"语音视频加密",
    a15:"不保存聊天记录",
    a16:" 您可以快速建立万人群组,在群里与多人进行聊天和文件分享,展开便捷高效的多方交流和对话,100人群是采用非对称超强加密技术,这个和端到端发送非对称加密技术是一样的,用户在群内每发送一条消息要加密100次通过服务器管道再加密发给这100个人,然后每个人使用自己的密钥进行解密,由于万人群性能要求,一条消息加密1万次通过服务器管道加密再发给10000人,客户端比较耗电,服务器性能受到影响,超过100人的万人群无法使用非对称超强加密,超过100人的万人群我们采用Telegram的代码开源万人群多向加密技术方法进行加密,也确保万人群数据安全。",
    a17:"万人群聊",
    a18:"分享语音",
    a19:"分享视频",
    a20:"分享文件",
    a21:" 社群里面有我的好友朋友圈（可以分享生活图片文字信息）、语音分享、在线电台（与主播在微社区和直播专区实时互动）、短视频视频分享、在线课程（可以在线分享自己的经验）、万人在线会议、视频会议,您可以发布动态来分享您的生活、见闻,也可以对其朋友发布的内容进行点赞、分享、打赏.",
    a22:"在线课程/会议",
    a23:"短视频分享",
    a24:"网络电台",
    a25:"文章分享",
    a26:" LockChat会基于万人群和社群时代开放给第三方接入各种web app,如游戏、购物、资讯等丰富多样的内容,致力于在各方面提升用户的使用体验,为广大的开发者提供一个大舞台。",
    a27:"    1. 移动平台开放: 接入开放平台,让你的应用支持分享、收藏和支付;",
    a28:" 2.支持用户登录: 让你的网站支持使用LockChat账号登录;",
    a29:"3. 行业解决方案: 成为开放平台的第三平台,为企业级用户提供运营服务和行业解决方案;",
    a30:"  4. IM服务: 为第三方企业提供IM服务和合作。",
    a31:"授权登陆",
    a32:"应用价值分享",
    a33:"社交游戏分享",
    a34:"行业解决方案",
    a35:"以用户为中心的设计体验",
    a36:"基于用户体验为中心的UI设计风格LockChat界面保持一致性和结构性,使用户感到轻松自在,体现清晰性、灵活性、效率性设计原则",
    a37:"LockChat开放平台",
    a38:"接入LockChat开放平台,也可以让你的app和web网站 ",
    a39:"支持使用LockChat帐号来登录",
    a40:"LockChat目前支持16个国家语言,是一个端到端加密",
    a41:"聊天平台,服务器不保存任何聊天记录,目前LockChat ",
    a42:"移动端支持 IOS & Android、H5，",
    a43:"电脑端支持H5、 WebApp",
    a44:"(Chrome.FireFox、Safari浏览器访问),Mac, ",
    a45:"Widonws版本。",
    a46:"价值创造者享有价值",
    a47:"LockChat为社区提供丰富的各类功能以及娱乐应用,我们倡导创造价值的用户应用享有价值,应用向第三方开发者进行开放,开发者和社群组织者可以运营自己的社区,随着社区用户规模的增大,社区价值也不断增长,开发者和社区发起者、社区会员可以通过应用分账模式进行收益。",
    a48:"了解更多",
    a49:"LockChat部落时代",
    a50:"LockChat是为社群连接而存在,我们意在打造有价值社群,重建用户彼此之间的连接与信任，科技的进步让用户之间的沟通方式不断的改变,从特定大众群体逐步转变至特定小众群体互联网的时代让大家更容易连接了,也因为太容易不受地域限制的连接,使得这个世界又回到“部落时代”,只是部落与部落之间不同于过去,她是开放的,随时可以互通,同时一个人不专属于一个特定的部落,他可以同时分属于几个不同部落之间",
    a51:"用户怎么说",
    a52:"非常感谢各个用户对LockChat的使用评价,如果你有",
    a53:"扫一扫下载LockChat,保护隐私,开始加密聊天之旅...",
    a54:"加密聊天",
    a55:"万人群聊",
    a56:"社群时代",
    a57:"开发平台",
    a58:"创建应用",
    a59:"通过填写应用名称、应用简介、应用图标,应用下载zip地址和应用测试的链接地址等信息,开发者可以创建移动应用、H5应用。",
    a60:"应用审核",
    a61:"开发者根据LockChat提供应用API进行产品开发,开发完毕后提交应用审核,LockChat团队将对应用信息进行审核,确保应用质量。",
    a62:"通过上线",
    a63:"开发者提交应用后,LockChat运营团队会进行审核,审核通过后开发者可以在LockChat应用商店看到自己的应用,并且可以在LockChat组织自己的用户进行推广。",
    a64:"内容创作平台: LockChat同时是一个内容创作平台,用户可以在进行: 在线会议、发视频、发图片、创作文章、电台、提问等的各种创作获得平台和用户的打赏,同时传播者也将获得传播价值奖励。",
    a65:"Game游戏应用:应用平台通过用户授权开放给第三方进行各类游戏,用户可以玩游戏有收益、分享游戏皆有奖励。",
    a66:"社群应用:用户可以将自己的各种应用产品如新闻、在线商城、物品交易应用集成到LockChat平台,通过社区来进行管理、拓展自己用户，包括可以管理用户关系以及使用平台对管理用户进行奖励。",
    a67:"因为区块链时代的来临让社群发挥了难以想象的力量,更让我们重新定义和认识社群的价值,更重要的是在于部落与部落之间更有效率的连接和价值交换,形成一个高效率且高效益的全新运转经济体。",
    a68:"1.部落价值交换",
    a69:"部落经济这个名词因为区块链⽽新回到⼤家的视􏰁,我们知道当下热⻔门书⽐如《部落文明》 ⾥面讲述的是文明的冲突,其实本质还是经济利益之间的冲突,今天我们要为部落经济正名,这是⼀群有共同兴趣、认识、价值观的⽤户发⽣群蜂效应,在一起互动、交流、协作、感染而产⽣的新的信任和价值关系,这种建⽴在社群之间的情感关联信任会产生新的部落经济。因为区块链时代的来临让社群发挥了难以想象的⼒量,更让我们重新定义和认识社群的价值,更要的是在于部落与部落之间更有效率的连接和价值交换,形成一个⾼效率且高效益的全新运转经济体。",
    a70:"2.部落经济",
    a71:"部落也是一种组织形态,要维持这个部落组织形态正常运转, 部落的每个个体都能产生价值和获得收益,并且还会进行自我周期迭代,和生物法则同一个道理,就像人体内的细胞一样,每个细胞都应该获得营养补给,死去一批细胞,要有新的细胞进行补位,从而保证组织体的结构完整性。",
    a72:"3.部落生物发展",
    a73:"部落本质上是一套小范围内的生物生态系统,部落本身要有自生长、自消化、自复制能力,并且以去中心化为基础,以部落共识为原则才能长久,社群共识机制决定了部落发展周期。",
    a74:"4.部落共识治理",
    a75:"更具互动价值的密聊元宇宙，",
    a76:"LockChat产品图像",
    a77:"下面是LockChat产品部分截图,展示部分界面及功能,如果",
    a78:"您想详细了解可以下载App",
    a79:"什么好的想法或者建议可以给我们发Email,",
    a80:"让LockChat体验更好!",
 }