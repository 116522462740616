import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Index from '../views/website/Index.vue'
import chanping from '@/views/website/chanping.vue'
import homeinfo from '@/views/website/homeinfo.vue'
import keyan from '@/views/website/keyan.vue'
import lianxi from '@/views/website/lianxi.vue'
import info from '../views/website/info.vue'
import jianginfo from '../views/website/jianginfo.vue'
import Home from '../views/Home/Home.vue'

const routes = [{
		path: '/',
		name: 'Home',
        redirect: '/Index',
		component: Home,
		children: [{
			path: '/Index',
			name: '首页',
			
			component: Index,
		},
		{
			path: '/chanping',
			name: '产品中心',
			component: chanping,
		},
		{
			path: '/homeinfo',
			name: '关于我们',
			component: homeinfo,
		},{
			path: '/keyan',
			name: '科研中心',
			component: keyan,
		},{
			path: '/lianxi',
			name: '联系我们',
			component: lianxi,
		},{
			path: '/info', 
			name: '产品详情',
			component: info,
			children: []
		},{
			path: '/jianginfo', 
			name: '奖章详情',
			component: jianginfo,
			children: []
		}]
	},
	
]

const router = createRouter({
	mode: 'hash',
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router