import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2806db0b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home",
  style: {}
};
const _hoisted_2 = {
  class: "home_title",
  style: {
    "margin-top": "122px"
  }
};
const _hoisted_3 = {
  class: "home_cont",
  style: {
    "margin-bottom": "46px",
    "display": "flex",
    "flex-direction": "column",
    "align-items": "center"
  }
};
const _hoisted_4 = {
  class: "bgicon"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "cont",
  style: {
    "width": "965.91px",
    "min-height": "773.72px"
  }
};
const _hoisted_7 = {
  class: "title"
};
const _hoisted_8 = ["innerHTML"];
import { useRoute } from "vue-router";
import { chat } from '@/api/index';
import { ref } from "vue";
import { urlConversion } from '@/utils/imgUrl';
export default {
  __name: 'jianginfo',
  setup(__props) {
    const {
      query
    } = useRoute();
    let bannerList = [{
      url: require('@/assets/img/jiang1.png'),
      text: '中国生命关怀协会生殖健康工作委员会 会员单位',
      cont: '中国生命关怀协会是国家一级协会 <br/>授权“钟爱一生-桃花故事” <br/>“生殖健康工作委员会”会员单位 <br/>共同推动中国生殖行业的发展',
      type: 1
    }, {
      url: require('@/assets/img/jiang2.png'),
      text: '生殖健康咨询师项目合作机构',
      cont: '“国家百姓生殖健康促进工程-北京卫计美联生殖医学研究院”<br/>联合“中国生命关怀协会-生殖健康工作委员会<br/>“授权“钟爱一生-桃花故事”<br/>桃花故事拥有“生殖健康咨询师”市场运作、教育培训、报名考证的权限',
      type: 2
    }, {
      url: require('@/assets/img/jiang3.png'),
      text: '百姓生殖健康爱心服务机构  ',
      cont: '在中国只授权3家龙头企业，并且审核标准相当的严格，<br/>1000家店起步，产品受国家指定第三方机构检测<br/>未来，桃花故事门店落地，<br/>门头上就可以打上“百姓生殖健康爱心服务站”',
      type: 3
    }, {
      url: require('@/assets/img/jiang4.png'),
      text: '广东省私密功能医学研究院授予 学术临床经验 营运转化中心',
      cont: '广东省私密功能医学研究院：是我国目前为止唯一一家针对<br/>男女功能型问题（非医疗、非药物）进行研究的医学研究院机<br/>构，在临床医学基础上，充分利用自然医学、询证医学、中草药<br/>中西医结合方式的一家研产学医的省级研究院；经过10年潜心研<br/>发、临床实验，为我国生殖临床医学、美业养生道路开辟出一条<br/>独特的功能型道路。',
      type: 4
    }, {
      url: require('@/assets/img/jiang5.png'),
      text: '和雅夫中西医结合医疗授予 学术临床经验 营运转化中心 ',
      cont: '和雅夫中西医结合医疗：和雅夫中西医结合门诊所（全国连<br/>锁），由中科（广东）生殖医学研究院、广东省私密功能医学研<br/>究院、再清纯生物集团共同投资兴建，致力并聚焦于私密妇科男<br/>科生殖健康领域精耕细作。',
      type: 5
    }, {
      url: require('@/assets/img/jiang6.png'),
      text: '中科(广东)生殖医学研究院授予 学术临床经验 营运转化中心',
      cont: '中科（广东）生殖医学研究院：由国家七个相关部门审批通<br/>过和监管的临床学术科研医学研究院，聚焦私密妇科男科、生殖<br/>与不孕不育、抗衰细分领域，拥有自主知识产权与专利及商标、<br/>著作、创新料体配方100多项。',
      type: 6
    }];
    let data = ref({
      image: ''
    });
    istype();
    function istype() {
      console.log(query.type);
      chat.getauctionaboutdetail({
        id: query.type
      }).then(res => {
        data.value = res.data;
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(_unref(data).title), 1)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
        src: _unref(urlConversion)(_unref(data).image),
        alt: ""
      }, null, 8, _hoisted_5)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(data).text), 1), _createElementVNode("div", {
        innerHTML: _unref(data).content,
        class: "cont-text"
      }, null, 8, _hoisted_8)])])]);
    };
  }
};