import request from '../../src/config/request';
export default {
    //banner
	getauctioninfobanner() {
		return request.request({
			url: '/auction/info/banner',
			method: "get",


		})
	},
    //产品
	getauctioninfogoods() {
      
		return request.request({
			url: '/auction/info/goods',
			method: "get",
			
		})
	},
	//产品详情
	getauctioninfodetail(data) {
      
		return request.request({
			url: '/auction/info/detail',
			method: "get",
			params:data
		})
	},
	 //提交
	 postinfosetcontent(data) {
      
		return request.request({
			url: '/auction/info/setcontent',
			method: "post",
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			  },
			data:data
		})
	},
	//关于我们
	getauctionaboutinfo() {
      
		return request.request({
			url: '/auction/about/info',
			method: "get",
			
		})
	},
	//奖状详情
	getauctionaboutdetail(data) {
      
		return request.request({
			url: '/auction/about/detail',
			method: "get",
			params:data
		})
	},
}