import "core-js/modules/es.array.push.js";
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b4aa19a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "right"
};
const _hoisted_3 = ["onClick"];
import { chat } from "@/api";
import { ref, onDeactivated } from "vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
export default {
  __name: 'Header',
  setup(__props) {
    const router = useRouter();
    const list = [{
      ztext: '首页',
      etext: 'HOME',
      path: '/'
    }, {
      ztext: '关于我们',
      etext: 'ABOUT US',
      path: 'homeinfo'
    }, {
      ztext: '产品中心',
      etext: 'PRODUCT CENTER',
      path: 'chanping'
    }, {
      ztext: '科研中心',
      etext: 'RESEARCH CENTER',
      path: 'keyan'
    }, {
      ztext: '联系我们',
      etext: 'CONTACT US',
      path: 'lianxi'
    }];
    let logoimg = [require('@/assets/img/home_imgbeijin.png'), require('@/assets/logo.png')];
    let topstyle = 'background: #B30000;';
    let textstyle = 'color:#fff;';
    let showhash = ref(true);
    onMounted(() => {
      // addscroll()
    });
    function gopath(item) {
      router.push(item.path);
    }
    function addscroll() {
      window.addEventListener('scroll', headerscroll);
    }
    function clickDiv(item) {
      let id = '';
      if (item.etext == 'HOME') {
        id = '#HOME';
      } else if (item.etext == 'ABOUT US') {
        id = '#ABOUTUS';
      } else if (item.etext == 'PRODUCT CENTER') {
        id = '#PRODUCTCENTER';
      } else if (item.etext == 'RESEARCH CENTER') {
        id = '#ESEARCHCENTER';
      } else if (item.etext == 'NEWS AND INFORMATION') {
        id = '#NEWSANDINFORMATION';
      } else if (item.etext == 'CONTACT US') {
        id = '#CONTACTUS';
      }
      console.log(id);
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }

    // function headerscroll() {
    // 	const top = document.documentElement.scrollTop || document.body.scrollTop
    // 	console.log('top', top);
    // 	if (top > 1000) {

    // 		showhash.value = true;
    // 	} else {
    // 		showhash.value = false;
    // 	}

    // }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: "header",
        style: _normalizeStyle(_unref(showhash) ? _unref(topstyle) : '')
      }, [_createElementVNode("div", {
        class: "header-box",
        style: _normalizeStyle(_unref(showhash) ? 'border-bottom:1px solid #fff;' : '')
      }, [_createElementVNode("img", {
        src: _unref(showhash) ? _unref(logoimg)[0] : _unref(logoimg)[1],
        alt: "",
        class: "logo"
      }, null, 8, _hoisted_1), _createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(list, (item, index) => {
        return _createElementVNode("div", {
          class: "item",
          onClick: $event => gopath(item)
        }, [_createElementVNode("span", {
          class: "ztext",
          style: _normalizeStyle(_unref(showhash) ? _unref(textstyle) : '')
        }, _toDisplayString(item.ztext), 5), _createElementVNode("span", {
          class: "etext",
          style: _normalizeStyle(_unref(showhash) ? _unref(textstyle) : '')
        }, _toDisplayString(item.etext), 5)], 8, _hoisted_3);
      }), 64))])], 4)], 4);
    };
  }
};