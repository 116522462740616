import configURL from '@/config/config'

export const urlConversion = (data) => {
	const regExp = new RegExp('http')
	const regExp2 = new RegExp('www')
	regExp.test(data);
	if (regExp.test(data) == true || regExp2.test(data)) {
		return data
	} else {
		if (data.slice(0, 1) == '/') {
			return configURL.imgurl + data
		} else {
			return configURL.imgurl + '/' + data
		}
	}
}