import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-76999a30"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
import { ref, reactive } from 'vue';
// import { chat } from '@/api/index';
import configURL from '@/config/config.js';
import router from '@/router';
import { useRoute } from "vue-router";
import { chat } from '@/api/index';
export default {
  __name: 'info',
  setup(__props) {
    const {
      query
    } = useRoute();
    let baseURL = configURL.imgurl;
    let imgSrc = reactive('');
    let content = ref('');
    show();
    function show() {
      console.log(baseURL);
      // if (router.currentRoute.value.query.name == '闺蜜私语') {
      // 	imgSrc = require('@/assets/gmsy.jpg')
      // } else if (router.currentRoute.value.query.name == '护花使者') {

      // 	imgSrc = require('@/assets/hhsz.jpg')
      // } else if (router.currentRoute.value.query.name == '冰清玉洁') {

      // 	imgSrc = require('@/assets/bqyj.jpg')
      // } else if (router.currentRoute.value.query.name == '两情相悦') {

      // 	imgSrc = require('@/assets/lqxy.jpg')
      // } else if (router.currentRoute.value.query.name == '春暖花开') {

      // 	imgSrc = require('@/assets/cnhk.jpg')
      // } else if (router.currentRoute.value.query.name == '锦上添花') {

      // 	imgSrc = require('@/assets/jsth.jpg')
      // }   
      chat.getauctioninfodetail({
        id: query.id
      }).then(res => {
        content.value = res.data.content;
        console.log(content.value);
      });
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        innerHTML: _unref(content),
        style: {
          "width": "100%"
        },
        class: "vhtml"
      }, null, 8, _hoisted_1);
    };
  }
};